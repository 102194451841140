import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Typography, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useSettingsService } from '../../../service/settings.service';
import PhoneNumberInput, {
  phoneNumberInputValidator,
  PhoneNumberInputValue,
} from '../../../component/PhoneNumberInput/PhoneNumberInput';
import { RootState } from '../../../store/store';
import { setPartialSettings } from '../../../store/user.slice';
import { openWaChat } from '../../../helper/message';

const { Paragraph } = Typography;

const WhatsappNumberForm = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state: RootState) => state.user);
  const settingsService = useSettingsService();
  const [form] = Form.useForm();

  const [disableSave, setDisableSave] = useState(false);

  const handleChangeWhatsappPhoneNumber = async (value: {
    phoneNumber: PhoneNumberInputValue;
  }) => {
    const { phoneNumber: newValue } = value;
    if (
      newValue.phoneNumber === settings.whatsappPhoneNumber &&
      newValue.phoneNumberPrefix === settings.whatsappPhoneNumberPrefix
    )
      return;

    const validPhoneNumber = newValue.phoneNumber.startsWith('0')
      ? newValue.phoneNumber.substring(1)
      : newValue.phoneNumber;

    await settingsService.whatsappActivateRequest({
      phoneNumberPrefix: newValue.phoneNumberPrefix,
      phoneNumber: validPhoneNumber,
    });

    message.success(
      <>
        We have sent a verification link to your{' '}
        <a href={openWaChat()} target='_blank' rel='noreferrer'>
          WhatsApp
        </a>
        , please click on the link to activate WhatsApp notification.
      </>,
      10
    );

    dispatch(
      setPartialSettings({
        isWhatsappNotificationOn: false,
        isWhatsappVerified: false,
        whatsappPhoneNumber: validPhoneNumber,
        whatsappPhoneNumberPrefix: newValue.phoneNumberPrefix,
      })
    );
  };

  return (
    <Form onFinish={handleChangeWhatsappPhoneNumber} form={form}>
      <Form.Item
        name='phoneNumber'
        rules={[
          {
            validator: phoneNumberInputValidator({
              required: false,
              invalidError:
                'Please input a phone number that is reachable via WhatsApp',
            }),
          },
        ]}
        initialValue={{
          phoneNumberPrefix: settings.whatsappPhoneNumberPrefix || '+60',
          phoneNumber: settings.whatsappPhoneNumber || '',
        }}
        style={{ marginBottom: '0.5rem' }}
      >
        <PhoneNumberInput
          onChange={async (value) => {
            try {
              await form.validateFields();
              setDisableSave(!value.phoneNumber);
            } catch {
              setDisableSave(true);
            }
          }}
        />
      </Form.Item>
      {settings.isWhatsappVerified && (
        <Paragraph type='secondary' style={{ marginBottom: '0.5rem' }}>
          You must verify again if you&apos;ve changed your WhatsApp phone
          number.
        </Paragraph>
      )}
      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type='primary'
          icon={<EditOutlined />}
          htmlType='submit'
          style={{ marginLeft: 'auto' }}
          disabled={disableSave}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WhatsappNumberForm;
